import React from 'react';
import { IonModal } from '@ionic/react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { PROPS_TYPE } from './constant';
import { TextElem } from '../text';
import { FlexElem } from '../flex';
import closeSvg from '../../asset/svg/common/close-modal.svg';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { ButtonElem } from '../button';

export const Elem: React.FC<PROPS_TYPE> = ({
  open,
  onClose,
  children,
  closeAction,
  className,
  titleTid,
  isCloseButton,
  icon,
  canDismiss,
  authLink,
}) => {
  const onCloseAction = () => {
    onClose();
    closeAction && closeAction();
  };
  const handleClose = () => onCloseAction();

  return (
    <Modal
      isOpen={open}
      // initialBreakpoint={1}
      // breakpoints={[0, 1]}
      // handleBehavior="cycle"
      onDidDismiss={handleClose}
      canDismiss={canDismiss}
      // trigger="open-modal"
      className={className}
    >
      <FlexElem flexDirection="column" spacing={6}>
        {titleTid && (
          <FilterHeader>
            <TitleContainer>
              {icon && <ImgStyled src={icon} alt="modal image" />}
              <TextElem
                type="medium"
                size="label"
                color="textPrimary"
                tid={titleTid}
              />
            </TitleContainer>
            <CloseContainer onClick={handleClose}>
              <CloseSvg src={closeSvg} />
            </CloseContainer>
          </FilterHeader>
        )}
        <Content>{children}</Content>
        {isCloseButton && (
          <ButtonElem
            padding="16.5px 30px"
            tid="COMMON.MODAL.CLOSE_BUTTON"
            onClick={handleClose}
          />
        )}
      </FlexElem>
    </Modal>
  );
};

const ImgStyled = styled.img`
  @media screen and (max-width: 500px) {
    height: 17px;
    width: 17px;
  }
`;

const CloseSvg = styled.img`
  width: 18px;
  height: 18px;
`;

const CloseContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(1)};
  background: unset;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 500px) {
    span {
      font-size: 15px;
    }
  }
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Modal = styled(IonModal as any)`
  pointer-events: none;
  * {
    pointer-events: all;
  }

  --height: auto;
  --backdrop-opacity: 0;
  --box-shadow: none;
  background: radial-gradient(
    circle,
    rgba(46, 60, 77, 0.6),
    rgba(9, 25, 45, 0.6)
  );
  backdrop-filter: blur(5px);

  ::part(content) {
    pointer-events: auto;
    max-height: 90vh;
    overflow-y: auto !important;
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    padding: ${Spacing(6)};
    /* max-width: max-content; */
    border-radius: ${Spacing(4)};
    top: calc((50% - 200px) / 2);
    left: calc((100% - 260px) / 2);
    margin: ${Spacing(5)};
  }
  & > div {
  }
  &.modal-handle::before {
    display: none;
    content: none;
  }
`;

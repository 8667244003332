import React, { useState } from 'react';
import styled from 'styled-components';

import { TextElem } from '../text';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../theme/value';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { ModalElem } from '../modal';
import { FlexElem } from '../flex';

export const Elem: React.FC<{
  listLimit?: number;
  list: string[];
  onToggle?: Function;
  color?: COLOR_TYPE;
  isUnderline?: boolean;
  lineHeight?: string;
  isModal?: boolean;
  modalTitle?: string;
  modalDescription?: string;
}> = ({
  listLimit = 3,
  list,
  onToggle,
  color = 'textSecondary',
  isUnderline,
  lineHeight = '1.6em',
  isModal,
  modalTitle,
  modalDescription,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [isModalInfo, setIsModalInfo] = useState(false);

  const toggleReadMore = (e: any) => {
    if (isModal) {
      setIsModalInfo(true);
      return;
    }

    e.stopPropagation();
    setIsReadMore(!isReadMore);
    onToggle && onToggle();
  };

  const isReadMoreText = list.length > listLimit;
  const isLarge = isReadMore && isReadMoreText;

  return (
    <>
      <ChipContainer>
        {!isLarge &&
          list.map((e, index) => (
            <Chip key={`method-${index}`}>
              <TextElem type="regular" color="chip" size="chip" tid={e} />
            </Chip>
          ))}
        {isLarge &&
          list.slice(0, listLimit).map((e, index) => (
            <Chip key={`method-${index}`}>
              <TextElem type="regular" color="chip" size="chip" tid={e} />
            </Chip>
          ))}
        {isReadMoreText && (
          <ChipReadMore onClick={toggleReadMore}>
            <TextElem
              className="readMore"
              type="regular"
              color="textPrimary"
              size="chip"
              tid={isReadMore ? `Більше` : 'Згорнути'}
            />
          </ChipReadMore>
        )}
      </ChipContainer>
      <ModalElem
        isCloseButton
        onClose={() => setIsModalInfo(false)}
        open={isModalInfo}
        titleTid={modalTitle}
      >
        <FlexElem flexDirection="column" spacing={3}>
          <UlStyled>
            {list.map((e) => {
              return (
                <CustomListItem key={e}>
                  <TextElem
                    type="regular"
                    color="textSecondary"
                    size="input"
                    tid={e}
                  />
                </CustomListItem>
              );
            })}
          </UlStyled>
          <TextElem
            lineHeight="1.5em"
            type="regular"
            color="textSecondary"
            size="input"
            tid={modalDescription}
          />
        </FlexElem>
      </ModalElem>
    </>
  );
};

const UlStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const CustomListItem = styled.li`
  position: relative;
  padding-left: ${Spacing(3)};
  line-height: 1em;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  }
`;

const Chip = styled.div`
  padding: ${Spacing(2)} ${Spacing(2.5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT_OPACITY]};
  width: max-content;

  @media screen and (max-width: 390px) {
    width: unset;
  }
`;

const ChipReadMore = styled(Chip)`
  cursor: pointer;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

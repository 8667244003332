import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { CARETAKER_ITEM_DATA_INTER } from '../constant';
import { TextElem } from '../../../common/text';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../../theme/value';
import { GridElem } from '../../../common/grid';
import { i18n } from '../../../lib/lang';
import { FlexElem } from '../../../common/flex';
import { getYearLabel } from '../../../lib/util/yearConvert';
// import { getHourLabelWithoutNumber } from '../../../lib/util/hourConvert';
// import { ChipMoreListElem } from '../../../common/chip-more-list';
import { ModalElem } from '../../../common/modal';

import { Spacing } from '../../../theme';
import { COLOR_ENUM, COLOR_DATA } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';

import videoIcon from '../../../asset/svg/common/play-video.svg';
import userPhoto from '../../../asset/svg/user/user-photo.svg';
import knowledge from '../../../asset/svg/user/knowledge.svg';
import arrow from '../../../asset/svg/user/arrow-up.svg';
import { ReadMoreModal } from './modal-about';
import { ChipMoreListElem } from '../../../common/chip-more-list';
import { CreateConsultationContainer } from '../../create-order';
// import { ReadMoreModal } from './modal-about';

export const Card: React.FC<{
  data: CARETAKER_ITEM_DATA_INTER;
  onClickHandler: (id: string) => void;
  selectedCategory?: string | number;
  id?: string;
}> = ({ data, selectedCategory, onClickHandler }) => {
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false);

  const href = `/specialist/${data?.id}`;

  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      onClickHandler(data?.id);

      history.push(href, { from: history.location.pathname });
    }
  };

  const openModalPreview = (e: any) => {
    e.stopPropagation();
    setModalPreviewVisible(true);
  };

  const closeModalPreview = (e: any) => {
    setModalPreviewVisible(false);
  };

  const price = 1000;

  const duration = 60;

  console.log('data.gender', data);

  return (
    <>
      <Container>
        <ModalElem
          open={modalPreviewVisible}
          onClose={(e: any) => closeModalPreview(e)}
        >
          <VideoStyled controls>
            <source src={data?.videoIntroduction?.url} type="video/mp4" />
            Your browser does not support the video tag.
          </VideoStyled>
        </ModalElem>
        <Content>
          <MainContent>
            <ImgWrapper
              onClick={(e) =>
                data?.videoIntroduction ? openModalPreview(e) : null
              }
            >
              {data?.videoIntroduction?.url && (
                <VideoImg>
                  <VideoIcon src={videoIcon} alt="video svg" />
                </VideoImg>
              )}
              <ImgStyled src={data?.photo?.url ?? userPhoto} />
            </ImgWrapper>
            <Info flexDirection="column" spacing={3}>
              <NameContainer flexDirection="column" spacing={3}>
                <Title
                  className="name"
                  type="medium"
                  oneLine
                  size="main"
                  color="textPrimary"
                >
                  {data?.name ?? i18n.t('COMMON.NO_NAME')}{' '}
                </Title>
                <FlexElemStyled alignItems="center" spacing={1}>
                  <Description size="semiSmall" tid={data.gender} />

                  <Description size="semiSmall" tid={data.age?.toString()} />

                  {data?.yearExperience && (
                    <>
                      <Dot />
                      <Description
                        // tid="CARETAKER_LIST.LIST.EXPERIENCE"
                        tid={`${data?.yearExperience} досвіду`}
                        type="semi-bold"
                        size="semiSmall"
                        color="iconThird"
                      />
                      <Dot />
                    </>
                  )}

                  <Description
                    size="semiSmall"
                    tvalue={{ value: 40 }}
                    tid="CARETAKER_LIST.LIST.AMOUNT_ORDER"
                  />
                </FlexElemStyled>
                {/* <FlexElem spacing={1}>
                  <img src={knowledge} />
                  <TextElem
                    color="textPrimary"
                    type="regular"
                    size="chip"
                    tid="Перевірений спеціаліст"
                  />
                </FlexElem> */}
              </NameContainer>

              {/* <AStyledMobile href={href} onClick={onClick}>
                <TextElem
                  type="regular"
                  size="small"
                  color="textPrimary"
                  tid="CARETAKER_LIST.LIST.BUTTON_MOBILE"
                  oneLine
                />
                <ArrowStyledMobile src={arrow} alt="arrow" />
              </AStyledMobile>
 */}
              {price && (
                <PriceContainer>
                  <Title
                    size="medium"
                    type="bold"
                    color="default"
                    tvalue={{ value: price }}
                    tid={
                      selectedCategory
                        ? 'CARETAKER_LIST.LIST.PRICE'
                        : 'CARETAKER_LIST.LIST.EMPTY_CATEGORY_PRICE'
                    }
                  />
                  {selectedCategory && (
                    <TextElem
                      size="small"
                      type="regular"
                      color="textSecondary"
                      tid="CARETAKER_LIST.LIST.DURATION"
                      tvalue={{ value: duration }}
                    />
                  )}
                </PriceContainer>
              )}
            </Info>
          </MainContent>
          {/* 
          {data?.requestList.length > 0 && (
            <ChipMoreListElem
              isModal
              modalDescription={data.requestCustom}
              modalTitle="CARETAKER_LIST.LIST.REQUEST_MODAL_TITLE"
              list={data.requestList}
              listLimit={data.requestList.length == 6 ? 6 : 5}
            />
          )} */}
          {data?.workAnimalType && data?.workAnimalType?.length > 0 && (
            <ChipMoreListElem
              isModal
              modalTitle="CARETAKER_LIST.LIST.METHOD_MODAL_TITLE"
              list={data.workAnimalType || []}
              listLimit={3}
            />
          )}
          {data?.categoryView && data?.categoryView?.length > 0 && (
            <ChipMoreListElem
              isModal
              modalTitle="CARETAKER_LIST.LIST.METHOD_MODAL_TITLE"
              list={data.categoryView || []}
              listLimit={3}
            />
          )}

          {(data?.profileDescription || data?.aboutPerson) && (
            <ReadMoreModal
              aboutMyPath={data?.profileDescription}
              aboutMe={data?.aboutPerson}
            />
          )}
        </Content>
        <CreateConsultationContainer
          category={data?.category && data?.category[0]}
          psychoId={data.id}
        />
      </Container>
    </>
  );
};

const FlexElemStyled = styled(FlexElem)`
  flex-wrap: wrap;
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.ICON_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;
const Description = styled(TextElem)`
  @media screen and (max-width: 630px) {
    font-size: 12px;
  }

  @media screen and (max-width: 336px) {
    font-size: 10px;
  }
`;

const ArrowStyledMobile = styled.img`
  width: 13px;
  height: 13px;
`;

const NameContainer = styled(FlexElem)`
  @media screen and (max-width: 630px) {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;

const Info = styled(FlexElem)`
  padding: ${Spacing(2)} ${Spacing(1)};

  @media screen and (max-width: 630px) {
    padding: 0;
  }
`;

const MainContent = styled(FlexElem)`
  @media screen and (max-width: 630px) {
    flex-direction: column;
    gap: ${Spacing(5)};
  }
`;

const Content = styled.div`
  display: grid;
  gap: ${Spacing(4)};
  height: max-content;
  width: 100%;
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  max-height: 55vh;
`;

const GridElemStyled = styled(GridElem)`
  gap: 10px;

  @media screen and (max-width: 630px) {
    gap: ${Spacing(2)};
  }
`;

const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spacing(2)};
  align-items: center;
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 630px) {
    font-size: 15px;
  }
`;

const AStyled = styled.a`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: end;
  transition: ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};
  cursor: pointer;
  padding: 7px ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  gap: ${Spacing(1)};
`;

const AStyledDesctop = styled(AStyled)`
  @media screen and (max-width: 630px) {
    display: none;
  }
`;

const AStyledMobile = styled(AStyled)`
  display: none;

  @media screen and (max-width: 630px) {
    display: flex;
    margin: ${Spacing(2)} 0;
  }
`;

const VideoImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_DATA[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: ${Spacing(2)};
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;

  @media screen and (max-width: 340px) {
    padding: 14px;
  }
`;

const VideoIcon = styled.img`
  @media screen and (max-width: 340px) {
    height: ${Spacing(3)};
    width: ${Spacing(3)};
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 150px;
  max-width: 150px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;

  @media screen and (max-width: 630px) {
    height: 256px;
    max-width: unset;
  }
`;

const ImgStyled = styled.img`
  display: block;
  min-height: 100%;
  min-width: 100%;
  max-height: 150px;
  max-width: 150px;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  object-fit: cover;
  object-position: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
  padding: ${Spacing(5)};
  width: 100%;
  align-items: start;
  gap: ${Spacing(4)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};
`;
